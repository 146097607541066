import App from 'next/app'
import { ThemeProvider } from 'emotion-theming'
import { appWithTranslation, i18n as i18next } from 'server/i18n'
import { lngFromReq } from 'next-i18next/dist/commonjs/utils'
import theme from 'theme'
import GlobalStyles from 'components/GlobalStyles'
import BaseLayout from 'layouts/Base'
import { NextSeo } from 'next-seo'
import getConfig from 'next/config'
import { setCookie, parseCookies } from 'nookies'
import useFetch from '@hmn/use-fetch'
import * as Sentry from '@sentry/node'
import { RewriteFrames } from '@sentry/integrations'

import StorageService from 'services/storageService'
import { getBoatLink } from 'helpers/middleware'

import CookieNotice from 'components/CookieNotice'
import ContextWrapper from 'components/ContextWrapper'
import Footer from 'components/Footer'
import Navigation from 'components/Navigation'
import { QueryClientProvider, QueryClient } from 'react-query'
import SEO from '../next-seo.config'
import 'abortcontroller-polyfill'
import 'custom-event-polyfill'

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig()
const { DEFAULT_THEME } = publicRuntimeConfig

// Override default theme here, this can be exposed through state or API
const currentTheme = null

const queryClient = new QueryClient()

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const distDir = `${serverRuntimeConfig.rootDir}/.next`
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        integrations: [
            new RewriteFrames({
                iteratee: frame => {
                    frame.filename = frame.filename.replace(distDir, 'app:///_next')
                    return frame
                }
            })
        ],
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
    })
}

useFetch.init(StorageService)

class MerkApp extends App {
    static async getInitialProps({ Component, ctx }) {
        const { isServer, req } = ctx

        const i18nLanguage = (req || {}).i18n ? lngFromReq(req) : (req || {}).lng

        try {
            const preferredLanguage = parseCookies(isServer ? ctx : {}).pref_lng
            if (preferredLanguage && preferredLanguage.length && preferredLanguage !== i18nLanguage) {
                await i18next.changeLanguage(preferredLanguage)
            }
        } catch (e) {
            //
        }

        if (i18nLanguage && i18nLanguage.length) {
            setCookie(isServer ? ctx : {}, 'i18n_lng', i18nLanguage, {
                maxAge: 30 * 24 * 60 * 60,
                path: '/'
            })
        }

        let pageProps = {}

        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx)
        }

        if (i18nLanguage !== 'en') {
            await new Promise(resolve => {
                i18next.loadLanguages('en', resolve)
            })
        }

        return {
            i18nLanguage,
            pageProps
        }
    }

    async componentDidMount() {
        const { router, i18nLanguage = 'en' } = this.props
        router.beforePopState(({ as }) => {
            if (process.browser) window.location.href = as
        })

        try {
            const preferredLanguage = parseCookies({}).pref_lng
            const i18nLanguageCookie = parseCookies({}).i18n_lng

            /**
             * Cache workaround, set the preferred language if cached page was served in wrong language
             */
            if (preferredLanguage && preferredLanguage.length) {
                const detectedLanguage =
                    i18nLanguageCookie && i18nLanguageCookie.length ? i18nLanguageCookie : i18nLanguage

                if (preferredLanguage !== detectedLanguage) {
                    /* eslint-disable-next-line no-console */
                    console.log(`[Merk]: Changing language to preferred language - ${preferredLanguage}.`)
                    // await i18next.changeLanguage(preferredLanguage)
                    const { query } = router
                    if (query.buy) {
                        const { as, href } = getBoatLink(query.type, query.model, query.slug, preferredLanguage, true)
                        await i18next.changeLanguage(preferredLanguage)
                        router.replace(href, as)
                    } else {
                        await i18next.changeLanguage(preferredLanguage)
                    }
                }
            }
        } catch (e) {
            //
        }
    }

    render() {
        const { Component, router, pageProps } = this.props
        const hasFooter = () => router.route !== '/inquiry' && router.route !== '/_error'
        const isListing = () => router.route !== '/specials'
        const hasNavigation = () => router.route !== '/specials'

        return (
            <>
                <ThemeProvider theme={theme[currentTheme || DEFAULT_THEME]}>
                    <NextSeo {...SEO} />
                    <GlobalStyles />
                    <ContextWrapper>
                        <BaseLayout>
                            {hasNavigation() && <Navigation listing={isListing()} {...pageProps} />}
                            <QueryClientProvider client={queryClient}>
                                <Component {...pageProps} />
                            </QueryClientProvider>
                            {hasFooter() && <Footer />}
                        </BaseLayout>
                    </ContextWrapper>
                    <CookieNotice />
                </ThemeProvider>
            </>
        )
    }
}

export default appWithTranslation(MerkApp)
